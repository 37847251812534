import React, { useEffect, useState } from "react";
// import pcmcsarthi from "../asset/pcmcsarthi.png";
import { Button,Nav} from "react-bootstrap";
import { useNavigate } from "react-router-dom";
//import logo from "../asset/pcmcsarthi.png"
import "../App.css";
import { isDisabled } from "@testing-library/user-event/dist/utils";
// import logo from '../asset/pcmcsarthi.png';
import logo from '../asset/pcmcLogo.png'
import { connect, useDispatch, useSelector } from 'react-redux';
import * as Icon from "react-bootstrap-icons";

import * as Action from '../redux/Action/index'

const area = [
  {
    code:"zone",
    path1:"/zone",
    name:"Gat Zone"
  },
  {
    code:"amount",
    path1:"/amount",
    name:"Amount"
  }
]

export function NavBar({ activeButton,active }) {

  const navigate = useNavigate();
  const dispatch = useDispatch()
  const [change,setChange] = useState(false)
  const [activeArea,setActiveArea] = useState("/")
  const [btndis,setbtndis] =useState("disabled")
  const handleLogout = () => {
    localStorage.removeItem("User");
    navigate("/login");
  };
  // const handleClick =(path,id)=>{
  //   console.log("sadsa",path,id)
  //   navigate(path)
  //   setActiveArea(activeArea ==="/main/zone" ? area[1].path1 : area[0].path1)
  //   setChange(true)
  //   // document.getElementById(id).style.backgroundColor="black"

  // }

  
  const activeSelector =  useSelector((state)=>state.admin.ActiveButton)
  console.log("activeSelector",activeSelector);
  const userData = localStorage.getItem("User");
  console.log("ActiveButton",activeButton)
  console.log("User",active);
  return (
    <nav className="navbar" style={{ backgroundColor: "#FFFFFF" }}>
      <div className="container d-flex">
        <div>
        <a className="navbar-brand d-block mx-auto" href="/">
          <img src="https://gsda-images.s3.ap-south-1.amazonaws.com/gsad//1676872820949_.png" alt="Bootstrap"/>
        </a>
        </div>
        <div>
         {/* <button className={ activeButton === "/main/zone" ? 'btnActive' : 'btnNormal' } onClick={ () => {
          navigate("/main/zone")
          active("/main/zone")
         } }>Zone</button>
         <button style={{marginLeft:"10px"}} className={ activeButton === "/main/amount" ? 'btnActive' : 'btnNormal' } onClick={ () => {
          navigate("/main/amount")
          active("/main/amount")
         } }>Amount</button> */}
         <button className="btn-logout" onClick={ () => {
          localStorage.clear("ptaxUser")
          navigate("/")
         } }>   
        <Icon.BoxArrowLeft className=" me-2"/> Logout</button>
        {/* <button className="btn-toggle" style={{border:"none",background:"none",marginLeft:"50px"}} onClick={()=>  handleClick("/ammount","button2")}  id="button2">Amount</button> */}
      
        </div>

        


        

        {userData ? (
          <Button className="btn-submit" onClick={handleLogout}>
            <Icon.BoxArrowLeft className="me-2"/>Logout
          </Button>
        ) : null}
      </div>
    </nav>
  );
}

const mapStateToProps = (state) => {
  return{
    activeButton :state.admin.ActiveButton
  }
}

const mapDispatchToProps = dispatch => {
  return{
    active: (data) => dispatch(Action.setActiveList(data)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(NavBar)
