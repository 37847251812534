import {
    Active_List,
    EDIT_USER,
    Active_Button
  } from "../constant/index";
  
  const initialState = {
    loading: false,
    adminDetails: null,
    ActiveButton: "/main/zone",
    error: null,
    editUserData: null,
  };
  
  const adminReducer = (state = initialState, action) => {
    switch (action.type) {
      case "ADMIN_LOGIN_SUCCESS":
        return {
          ...state,
          adminDetails: action.payload,
          error: null,
        };
  
      case Active_Button:
        return {
          ...state,
          ActiveButton: action.payload,
          error: null,
        };
      
  
      case "LOGOUT":
        return {};
      default:
        return state;
    }
  };
  
  export default adminReducer;
  