import './App.css';
import { Route, Routes } from 'react-router-dom';
import MainLayout from './components/MainLayout';
import Zone from './components/Zone';
import Ammount from './components/Ammount';
import Login from './components/Login';
import Firstpage from './components/Firstpage';


function App() {
  return (
    <div>

      <Routes>
          <Route path='/' element={<Login/>}/>
          <Route path='/main' element={<MainLayout />}>
          <Route path='/main/zone' element={<Firstpage/>}/>
          <Route path='/main/zone2' element={<Zone/>}/>
          <Route path='/main/amount' element={<Ammount/>}/>
          
        </Route>
      </Routes>
    </div>
  );
}

export default App;
