import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import adminReducer from "./ActiveReducer";

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["admin"],
};

const reducer = combineReducers({
  admin: adminReducer,
});

export default persistReducer(persistConfig, reducer);
