import React from "react";
import "../App.css";

export default function Footer() {
  return (
    
    <footer
      style={{
        width: "100%",
        height: "40px",
        // color: "#d62aab",
        // backgroundColor: "#e3f2fd",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginTop: "auto",
        position:"fixed",
        bottom:0
      }}
    >
      <div className="m-0 text-center px-1" >
        <p className="mb-0">Powered by Foxberry Technologies Pvt. Ltd</p>
      </div>
    </footer>
  );
}
