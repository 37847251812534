

import React,{ useState,useEffect } from 'react';
import { Container, Row, Col, Card, Form, Button, InputGroup } from 'react-bootstrap';
import { Navigate, useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Spinner from 'react-bootstrap/Spinner';
import * as Icon from 'react-bootstrap-icons';
import axios from 'axios';
import { BASE_URL } from '../urls/urls';


const Login = () => {

  const notify = () => { toast.error("Please Enter Valid Credentials", {position: toast.POSITION.TOP_RIGHT}); }
  const mobileError = () => {toast.error("Please Enter Valid Mobile Number", {position: toast.POSITION.TOP_RIGHT}); }
  const PassError = () => { toast.error("Please Enter Password", {position: toast.POSITION.TOP_RIGHT}) }
  const APiError = () => {toast.warning("Something Went Wrong !!.Please Try Again", {position: toast.POSITION.TOP_RIGHT}); }
  // const Invalid = () => {toast.error("Invalid Login Credentials !!", {position: toast.POSITION.TOP_RIGHT}); }
  const navigate = useNavigate();

  const [Mobile, setMobile] = useState('');
  const [Pass, setPass] = useState('');
  const [loader,setLoader] = useState(false);
  const [showPassword,setshowPassword] = useState(false);

//   const navigate = useNavigate()

  

  const validate = () => {
    let FormValid = true;

    if(!Mobile){
      FormValid = false;
      mobileError()
    }else if(Mobile.match(/[A-Za-z+@#$&%!~=*./\s/g/\-/?<>,;`'""/]/) || Mobile.length !== 10){
      FormValid = false;
      mobileError()
    }else
    if(!Pass){
      FormValid = false;
      PassError()
    }
    return FormValid;
  }

  const loginApi = async ()=>{
    
    let header = new Headers();
    header.append("Content-Type", "application/x-www-form-urlencoded")
    let urlencoded = new URLSearchParams();
    // urlencoded.append("noOfData", 80);
    urlencoded.append("username", Mobile);
    urlencoded.append("password",Pass);
    const opt = {
        method: 'POST',
        headers: header,
        body: urlencoded,
    }
    // console.log("PAYLOAD-->",opt,userr,passs)
    // console.log("data dot zone",noOfData);
    // await fetch("https://api.pcmctax.foxberry.link/v1/p4y/ptax4yearList?zone=17",opt)
    await fetch(BASE_URL + `/user/login`, opt)

        .then(res => {
            return res.json()

        })
        .then((res)=>{
            // console.log("response login",res);
            if(res.username){
                navigate("/main/amount")
                localStorage.setItem("ptaxUser",JSON.stringify(res))
            }
        })
        .catch((err)=>{
            console.log(err);
        })

  }

  

  return (
    <div className='login'>
      <Container className='h-100'>
        <ToastContainer/>
        <Row className="d-flex justify-content-center align-items-center h-100 w-100">
          <Col xs={12} md={6}  data-aos="fade-up">
            <Card className="card p-4 text-center">
              <h1 className='heading mb-3'>LOG IN</h1>

            <Form className="px-3">

                {/* <Form.Control  placeholder="Mobile Number" value={Mobile} onChange={(e) =>  {
                  if(!e.target.value.match(/[A-Za-z+@#$&%!~=^_:(){}\[\]*./\s/g/\-/?<>,;`'""/]/) && e.target.value.length <= 10){
                    setMobile(e.target.value);
                  } } } className="mb-3"/> */}
                <Form.Control  placeholder="Username" value={Mobile} onChange={(e) =>  {
                  setMobile(e.target.value)
                } } className="mb-3"/>
               
                <InputGroup className='mb-3'  data-aos="fade-up" data-aos-delay="200" >
                  <Form.Control type={showPassword ? "text" : "password"} placeholder="Password" value={Pass} onChange={(e) =>{
                  if(!e.target.value.match(/[/\s/g/]/)){
                    setPass(e.target.value);
                  }}}  />
                  <InputGroup.Text>{ showPassword ? <Icon.EyeSlash style={{ cursor:'pointer' }} onClick={ () => setshowPassword(!showPassword) } /> : <Icon.Eye style={{ cursor:'pointer' }} onClick={ () => setshowPassword(!showPassword) } /> }</InputGroup.Text>
                </InputGroup>
                {/* <p className='mb-3 mt-1 ms-1 text-start text-secondary' style={{fontSize:'13px'}}>Ex. Pass@123</p> */}

                 {/* error ? "plzz" : null*/}
                <Button className="btn-submit" type="submit" onClick={ (e) => {
                    e.preventDefault();
                    loginApi()
                }}

                //  onClick={(e) => SendAdmin(e.preventDefault())}
                 >
                  login
                  {/* { loader ? <Spinner animation="border" variant="light" 
                 /> : 'Login' } */}
                 </Button>
              </Form>
            </Card>
          </Col>
        </Row>

      </Container>
    
      {/* <footer className="text-center py-2 fixed-bottom">
        <p className="m-0">&copy; Foxberry | 2022 | All Rights Reserved</p>
      </footer> */}

    </div>
  )
}

export default Login;

