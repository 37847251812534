import React, { useEffect, useState } from 'react';
import useInfiniteScroll from './useInfiniteScroll';
import { BASE_URL } from '../urls/urls';
import { Container, Row, Col, Card, Spinner } from 'react-bootstrap';

const Zone = () => {

useEffect(()=>{
    getIntialData("10", "0");
},[])

const [listItems, setListItems] = useState([]);
const [loader, setLoader] = useState(false);
const [noOfData,setNodata] = useState(10)


const getIntialData=async(noOfData, skip)=>{
setLoader(true);
        let urlencoded = new URLSearchParams();
        urlencoded.append("noOfData",noOfData );
        urlencoded.append("skip", skip);
        const opt = {
            method: 'POST',
            headers:{
                'Content-Type' : 'application/x-www-form-urlencoded',
              },
            body: urlencoded,
        }
        await fetch(BASE_URL + `/p4y/ptax4yearList`, opt).then(res => res.json()).
        then(response=>{
        console.log("get api data from initial", response);
        setListItems(response.data);
        setLoader(false)
    })
    .catch((err) => {
                console.log("error from api", err);
            })
    }

    console.log("ListItems before Return", listItems);


  
    const handleScroll = (e) => {
        const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
        if (bottom) { 
            console.log("bottom");
            let val = noOfData + 10
            setNodata(val)
            getIntialData(val, "0")

        } 
    }

  return (

<>
<Container>
    <Row className='d-flex mb-5 pb-5' onScroll={handleScroll}  style={{overflowY: 'scroll', maxHeight: '400px'}}>
     
        {
            listItems!==undefined && listItems.length!==0 ? listItems.map((row)=>{
                return(
                    <Col md={6}>
                        <Card className='m-2'>
                        <p style={{color:"black"}}>{row.propertycode_x}</p>
                        <p>{row.zone}</p>
                        <p>data</p>
                    </Card>
                    </Col>
                )
            
            }) :""
        }
   {loader ? <Spinner/>:""  }
    </Row>
</Container>
</>
   
  )
}

export default Zone;