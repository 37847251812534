import React, { useEffect, useState } from "react";
import { Container, Row, Col, Form, Spinner, Button } from "react-bootstrap";

import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { BASE_URL } from "../urls/urls";
import ReactPaginate from "react-paginate";
import * as Icon from "react-bootstrap-icons";
import { NavBar } from "./NavBar";

export default function Firstpage() {
  const [arrLength, setArrLength] = useState();
  const [property, setProperty] = useState([]);
  const [gatlist, setGatlist] = useState([]);
  const [filtergat, setfiltergat] = useState([]);
  const [zoneList, setZoneList] = useState([]);
  const [noData, setnoData] = useState();
  const [loader, setLoader] = useState(false);
  // const [recordPerPage,setRecordPerPage] = useState([])
  const [currentPage, setCurrentPage] = useState(1);
  const [postPerPage, setPostPerPage] = useState(10);
  const [skip, setSkip] = useState(0);
  const [count, setCount] = useState();
  const [flag, setFlag] = useState(false);
  // const [val,setVal] = useState

  const [pageCount, setPageCount] = useState(0);
  const [currentPageCount, setCurrentPageCount] = useState(1);

  // console.log("pageCount",pageCount)
  // console.log("currentPageCount",currentPageCount)

  const NoData = () => toast.warning("NO Data Found");
  const enterValidDetails = () => toast.error("select minimum 1 field");

  const [state, setState] = useState({
    zone: "",
    gat: "",
    noOfData: "10",
    recordPerPage: "",
  });
  const handlePageClick = async (data) => {
    setLoader(true);
    console.log("page change", data.selected);

    let currentPage = data.selected + 1;
    console.log("page", currentPage);
    const selectedpage = currentPage * postPerPage - postPerPage;
    initialData(postPerPage, selectedpage);
    console.log("skip page on click", selectedpage);
    // handleChange()

    // const commentsFormServer = await fetchComments(currentPage);

    // setItems(commentsFormServer);
    // scroll to the top
    //window.scrollTo(0, 0)
  };

  const next = () => {
    let val = skip + postPerPage;
    if (val >= count) {
      setSkip(count);
    } else {
      setSkip(val);
      flag ? handleClick(postPerPage, val) : initialData(postPerPage, val);
    }
    setCurrentPageCount(currentPageCount + 1);
    console.log(currentPageCount + 1);

    // handleClick(postPerPage, val)
    // console.log("next");
  };
  const prev = () => {
    let val = skip - postPerPage;
    // console.log("Val")
    setSkip(val);
    flag ? handleClick(postPerPage, val) : initialData(postPerPage, val);
    setCurrentPageCount(currentPageCount - 1);

    // handleClick(postPerPage, val)
    // console.log("next");
  };

  const validation = () => {
    let IsformValid = true;

    if (state.zone.length === 0 && state.gat.length === 0) {
      // IsformValid = false;
      // enterValidDetails()
      reload();
    }
    return IsformValid;
  };

  const reload = () => {
    window.location.reload();
  };

  // console.log("gat list", gatlist);

  let name1, value;
  const handleInputs = (e) => {
    // console.log(e);
    name1 = e.target.name;
    value = e.target.value;
    // console.log("name", name);
    setState({ ...state, [name1]: value });
  };
  const initialData = async (noOfData, skip) => {
    setLoader(true);
    // console.log("postPerPage", postPerPage);
    // let header = new Headers();
    // header.append("Content-Type":"application/x-www-form-urlencoded")
    let urlencoded = new URLSearchParams();
    // urlencoded.append("noOfData", 80);
    urlencoded.append("noOfData", noOfData);
    urlencoded.append("skip", skip);
    const opt = {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: urlencoded,
    };
    // console.log("initial data no dataq",noOfData);
    await fetch(BASE_URL + `/p4y/ptax4yearList`, opt)
      .then((res) => res.json())
      .then((response) => {
        setCount("");
        let arr = property;
        arr.concat(response?.data);
        console.log();
        setProperty(response?.data);
        setLoader(false);
        // console.log("get api data from initial", response);

        setCount(response?.count);
        setPageCount(Math.ceil(response.count / postPerPage));
      })
      .catch((err) => {
        console.log("error from api", err);
      });
  };

  const handleClick = async (noOfData, skip) => {
    if (validation()) {
      setLoader(true);

      const { zone, gat } = state;
      // console.log("postPerPage", postPerPage);

      const data = {
        zone: zone,
        gat: gat,
      };

      // console.log("handle Click no dataw",noOfData);

      // const data4 = {
      //   zone=(datad.zob)
      // }
      // axios.get(`http://api.pcmctax.foxberry.link/v1/p4y/ptax4yearList`)

      if (state.gat && state.zone) {
        // console.log("Both data")
        let header = new Headers();
        header.append("Content-Type", "application/x-www-form-urlencoded");
        let urlencoded = new URLSearchParams();
        // urlencoded.append("noOfData", 80);
        urlencoded.append("noOfData", noOfData);
        urlencoded.append("skip", skip);
        const opt = {
          method: "POST",
          headers: header,
          body: urlencoded,
        };
        // console.log("data dot zone",noOfData);
        // await fetch("https://api.pcmctax.foxberry.link/v1/p4y/ptax4yearList?zone=17",opt)
        await fetch(
          BASE_URL + `/p4y/ptax4yearList?zone=${data.zone}&gat=${data.gat}`,
          opt
        )
          .then((res) => {
            return res.json();
          })

          .then((res) => {
            // setState({...state, zone:"",gat:""})
            setCount("");
            setProperty("");
            setLoader(false);
            // console.log("get api data", res);
            if (res === "No data found") {
              NoData();
            } else {
              setProperty(res?.data);
              setCount(res.count);
              setPageCount(Math.ceil(res.count / postPerPage));
            }
          })
          .catch((err) => {
            setLoader(false);

            console.log("error from api", err);
          });
      } else if (state.gat) {
        // console.log("Only gat")
        let header = new Headers();
        header.append("Content-Type", "application/x-www-form-urlencoded");
        let urlencoded = new URLSearchParams();
        // urlencoded.append("noOfData", 80);
        urlencoded.append("noOfData", noOfData);
        urlencoded.append("skip", skip);
        const opt = {
          method: "POST",
          headers: header,
          body: urlencoded,
        };
        // await fetch("https://api.pcmctax.foxberry.link/v1/p4y/ptax4yearList?zone=17",opt)
        await fetch(BASE_URL + `/p4y/ptax4yearList?gat=${data.gat}`, opt)
          .then((res) => {
            return res.json();
          })

          .then((res) => {
            // setState({...state, gat:""})
            setCount("");

            // console.log("get api data", res);
            setProperty(res?.data);
            setCount(res.count);
            setPageCount(Math.ceil(res.count / postPerPage));
            setLoader(false);

            // setArrLength(res.data.length)
          })
          .catch((err) => {
            setLoader(false);

            console.log("error from api", err);
          });
      } else if (state.zone) {
        // console.log("only zone")
        let header = new Headers();
        header.append("Content-Type", "application/x-www-form-urlencoded");
        let urlencoded = new URLSearchParams();
        // urlencoded.append("noOfData", 80);
        urlencoded.append("noOfData", noOfData);
        urlencoded.append("skip", skip);
        const opt = {
          method: "POST",
          headers: header,
          body: urlencoded,
        };
        // await fetch("https://api.pcmctax.foxberry.link/v1/p4y/ptax4yearList?zone=17",opt)
        await fetch(BASE_URL + `/p4y/ptax4yearList?zone=${data.zone}`, opt)
          .then((res) => {
            return res.json();
          })

          .then((res) => {
            // setState({...state, zone:""})
            setCount("");

            // console.log("get api data", res);
            setProperty(res?.data);
            setLoader(false);

            // setArrLength(res.data.length)
            setCount(res.count);
            setPageCount(Math.ceil(res.count / postPerPage));
          })
          .catch((err) => {
            console.log("error from api", err);
            setLoader(false);
          });
      }
      // console.log("property", property);
    }
  };

  const handleSubmit = () => {
    handleClick(postPerPage, 0);

    setSkip(0);
    console.log("property", property);
  };

  const getGatList = () => {
    axios
      .get(BASE_URL + `/gat/gatlist`)
      .then((res) => {
        // console.log("response gat List", res.data);
        setGatlist(res.data);
        setfiltergat(res.data);
      })
      .catch((err) => {
        console.log("eroor from api", err);
      });
  };
  const getZone = () => {
    axios
      .get(BASE_URL + `/zone/zonelist`)
      .then((res) => {
        // console.log("response zon list", res.data);
        setZoneList(res.data);
      })
      .catch((err) => {
        console.log("eroor from api", err);
      });
  };

  console.log("Gat List Globalyly", gatlist);

  // console.log("length", arrLength);

  const indexOflastPost = currentPage * postPerPage;
  const indexOfFirstPost = indexOflastPost - postPerPage;
  const currentPost = property?.slice(indexOfFirstPost, indexOflastPost);

  useEffect(() => {
    if (state.zone === "" && state.gat === "") {
      getGatList();
      getZone();
      initialData(postPerPage, 0);
      setSkip(0);
    }
  }, [state.zone, state.gat]);

  const [selectedGat, setSelectedGat] = useState([]);
  useEffect(() => {
    if (state.zone) {
      getGat();
    }
  }, [state.zone]);

  const getGat = () => {
    console.log("state of zone", state.zone);
    console.log("Gat List", gatlist);

    const result = filtergat.filter((event) => event.zonekey == state.zone);
    console.log("result 123", result);
    setGatlist(result);
  };

  const handleScroll = (e) => {
    const bottom =
      e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
    if (bottom) {
      console.log("Reached at Bottom");
      let val = postPerPage + 10
      setPostPerPage(val)
      if(!state.zone && !state.gat){
        initialData(val, "0")
      }
      else{
        handleClick(postPerPage, "0");
      }
    }
  };

  return (
    <div>
      <NavBar />
      <Container>
        <ToastContainer />
        <Row className="d-flex justify-content-center align-items-center h-100">
          <Col md={10} className="text-center mb-3">
            <form>
              <Row className="justify-content-center px-5 mt-3">
                <Col xs={6} md={4}>
                  <label for="exampleFormControlSelect2">
                    <b>Select Zone </b>{" "}
                  </label>

                  <select
                    className="form-select form-select-lg .dropdown .dropdown-menu{ max-height:100px; overflow:auto; }"
                    aria-label=".form-select-lg example"
                    name="zone"
                    value={state.zone}
                    onChange={handleInputs}
                  >
                    <option value="">Zone</option>
                    {zoneList != undefined && zoneList != null
                      ? zoneList.map((data, i) => {
                          return (
                            <option value={data.zonekey}>
                              {data.zonename}
                            </option>
                          );
                        })
                      : ""}
                  </select>
                </Col>

                <Col xs={6} md={4} className="text-center">
                  <label for="exampleFormControlSelect2">
                    <strong>Select Gat </strong>
                  </label>

                  <select
                    className="form-select form-select-lg"
                    aria-label=".form-select-lg example"
                    name="gat"
                    value={state.gat}
                    onChange={handleInputs}
                    disabled={gatlist.length === 0}
                  >
                    <option value="">Gat </option>

                    {gatlist != undefined && gatlist != null
                      ? gatlist.map((data, i) => {
                          return (
                            <option value={data.gatkey}>
                              {data.mar_gatname}
                            </option>
                          );
                        })
                      : ""}
                  </select>
                </Col>
                {/* <Col xs={4} className='text-center d-flex justify-content-center'>
                                    <button type="submit" className="btn btn-submit mt-3 mt-md-0" >Get Data</button>
                                </Col> */}
                <Col
                  xs={12}
                  md={4}
                  className="d-flex justify-content-center align-items-center mt-2"
                >
                  <Button
                    type="submit"
                    className="btn btn-submit"
                    onClick={(e) => {
                      e.preventDefault();
                      setFlag(true);
                      handleClick(postPerPage, 0);
                      setSkip(0);
                    }}>
                    Get Data
                  </Button>
                  {/* <Button type="submit" className='btn btn-submit ms-3'onClick={ (e) => { e.preventDefault();setFlag(false);initialData();reload(); console.log("get data click") }} >Get All</Button> */}
                </Col>
              </Row>

              {count ? (
                <Row className="mt-4">
                  <Col>
                    <strong>Total Count :</strong> {count}
                  </Col>
                </Row>
              ) : (
                " "
              )}
            </form>
           
              <Col
                className="myCard pb-5"
                onScroll={handleScroll}
                style={{ height: "400px", overflowY: "scroll" }}>
                {currentPost?.map((data, i) => {
                  return (
                    <div className="card text-center m-4">
                      <div className="card-body w-100">
                        <Row>
                          <Col md={6}>
                            <p className="card-text md-6 ">
                              {" "}
                              <strong>Property Code X : </strong>
                              {data.propertycode_x}
                            </p>
                          </Col>
                          <Col md={6}>
                            <p className="card-title md-6">
                              <strong>Zone : </strong>
                              {data.zone}
                            </p>{" "}
                          </Col>
                        </Row>

                        <Row>
                          <Col md={6}>
                            <p className="card-text sm-12">
                              <strong>Gat :</strong>
                              {data.gat}
                            </p>
                          </Col>
                          <Col md={6}>
                            <p className="card-text">
                              <strong>Bill Amount : </strong>
                              {data.billamount}
                            </p>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  );
                })}

                {loader ? <Spinner/> :""}
              </Col>
        
          </Col>
        </Row>
      </Container>
    </div>
  );
}
