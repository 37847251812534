import React, { useEffect, useState } from "react";
import { Container, Row, Col, Form, Spinner, Button, InputGroup, Card } from "react-bootstrap";
import '../App.css';
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { BASE_URL } from "../urls/urls";
import { NavBar } from "./NavBar";

import  * as Icon from 'react-bootstrap-icons';
import * as XLSX from 'xlsx';
// import ReactPaginate from "react-paginate";
// import * as Icon from "react-bootstrap-icons";


export default function Ammount() {
    const [arrLength, setArrLength] = useState()
    const [property, setProperty] = useState([])
    const [currentPage, setCurrentPage] = useState(1)
    const [postPerPage, setPostPerPage] = useState(10)
    const [record, setRecord] = useState(5)
    const [count, setCount] = useState();
    const [loader,setLoader] =useState(false)
    const [flag, setFlag] = useState(false)
    const [pageCount,setPageCount] = useState(0)
    const [currentPageCount,setCurrentPageCount] = useState(1)

    // console.log("pageCount",pageCount)
    // console.log("currentPageCount",currentPageCount)

    const [gatlist, setGatlist] = useState([])
    const [filtergat,setfiltergat] = useState([])
    const [zoneList, setZoneList] = useState([])
    

    const Nodata = () => toast.warning("No data Found")
    const val1 =()=>toast.error("Select Minimum One Field")
    // const EndAmount =()=>toast.error("Enter End Amount")
    // const enterVolidAmmount =()=>toast.error("Start amount should less than end amount ")

    const [state, setState] = useState({
        startAmmount: "",
        endAmmount: "",
        recordPerPage: "",
        zone: "",
        gat: "",
        
    })
    
    

    useEffect(() => {
        initialData(postPerPage, skip)

    }, [])

  const reload = ()=>{
    window.location.reload()
  }

    // setPostPerPage(state.recordPerPage)
    const next = () => {
        let val = skip + postPerPage
        if (val >= count) {
            setSkip(count)
        } else {
            setSkip(val)
            
            // handleClick(postPerPage, val)
            //  initialData(postPerPage,val)


            flag  ? handleClick(postPerPage, val) : initialData(postPerPage,val)
            // setCurrentPageCount(currentPageCount+1)


        }

        setCurrentPageCount(currentPageCount+1)

        // console.log("next",val);
    }
    const prev = () => {
        let val = skip - postPerPage
        // console.log("sbsajd", val)
        setSkip(val)
        // handleClick(postPerPage, val)
        // initialData(postPerPage,val)
    flag  ? handleClick(postPerPage, val) : initialData(postPerPage,val)
        // console.log("next");
        setCurrentPageCount(currentPageCount-1)

    }

    let name, value;
    const handleInputs = (e) => {
        
        
        name = e.target.name;
        value = e.target.value;
        setState({ ...state, [name]: value });
        // handleClick(postPerPage,0);setSkip(0)
    };
    const handleChange = (e) => {
        setPostPerPage(e.target.value)
        console.log("Record per page new", e.target.value);
    }
    // console.log("record11", postPerPage);
    // const validation1 = ()=>{
    //     let IsformValid = true;

    //     if(!state.startAmmount){
    //         IsformValid = false;
    //         startAmmount()
    //     }
    //     if(!state.endAmmount){
    //         IsformValid = false;

    //         EndAmount()
    //     }
    //     if(  parseInt(state.startAmmount) >  parseInt(state.endAmmount) ){
    //         IsformValid = false;

    //         enterVolidAmmount()
    //     }
    //     return IsformValid;


    // }
    const validation = ()=>{
        let IsformValid = true;

        if(!state.startAmmount && !state.endAmmount && !state.gat && !state.zone){
            IsformValid = false;
            val1()
            
        }
        return IsformValid;

    }
    const handlePageClick = async (data) => {
        setCurrentPage()
        setLoader(true);
        console.log("page change", data.selected);
    
        let currentPage1 = data.selected + 1;
        setCurrentPage(currentPage1)
        console.log("page", currentPage);
        const selectedpage = currentPage * postPerPage - postPerPage;
        initialData(postPerPage, selectedpage);
        // console.log("skip page on click", selectedpage);
        // handleChange()
    
        // const commentsFormServer = await fetchComments(currentPage);
    
        // setItems(commentsFormServer);
        // scroll to the top
        //window.scrollTo(0, 0)
      };

    


    const initialData = async (noOfData, skip) => {
        setLoader(true)
        let urlencoded = new URLSearchParams();
        urlencoded.append("noOfData", noOfData);
        urlencoded.append("skip", skip);
        const opt = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            body: urlencoded,
        }
        await fetch(BASE_URL + `/p4y/ptax4yearList`, opt).then(res => res.json())
        .then(response => {
            // setProperty("")
            setLoader(false)
            setCount()
            setProperty()
            console.log("get api data from initial", response);
            setProperty(response?.data)
            setCount(response?.count)
            setPageCount(Math.ceil(response.count/postPerPage)) })
            .catch((err) => {
                setLoader(false)
                console.log("error from api", err);
            })

    }
       
    const handleClick = async (noOfData, skip) => {
        if(validation()){
            setLoader(true)
        //    console.log("vghxvbcjdbb data 7797",noOfData)
        
        const { startAmmount, endAmmount,zone,gat } = state

        const data = {
            startAmmount: startAmmount,
            endAmmount: endAmmount,
            zone:zone,
            gat:gat
        }

        if (state.gat && state.zone && state.startAmmount && state.endAmmount) {
            // console.log("Both data")
            let header = new Headers();
            header.append("Content-Type", "application/x-www-form-urlencoded")
            let urlencoded = new URLSearchParams();
            // urlencoded.append("noOfData", 80);
            urlencoded.append("noOfData", noOfData);
            urlencoded.append("skip", skip);
            const opt = {
                method: 'POST',
                headers: header,
                body: urlencoded,
            }
            console.log("jitu",state.startAmmount);
            // console.log("data dot zone",noOfData);
            // await fetch("https://api.pcmctax.foxberry.link/v1/p4y/ptax4yearList?zone=17",opt)
            await fetch(BASE_URL + `/p4y/ptax4yearList?zone=${data.zone}&gat=${data.gat}&startAmount=${data.startAmmount}&endAmount=${data.endAmmount}`, opt)

                .then(res => {
                    return res.json()

                })
     
                .then((res) => {
                    
                    // setState({...state, zone:"",gat:""})
                    setCount("")
                    setProperty("")
                    setLoader(false)
                    // console.log("get api data", res);
                    if(res === "No data found"){
                        // NoData()
                     }
                    else{
                        setProperty(res?.data)
                        setCount(res.count);
                        setPageCount(Math.ceil(res.count/postPerPage))
                    }
                })
                .catch((err) => {
                    setLoader(false)

                    console.log("error from api", err);
                })
        }
         else if (state.gat && state.zone && state.startAmmount) {
            // console.log("Only gat")
            let header = new Headers();
            header.append("Content-Type", "application/x-www-form-urlencoded")
            let urlencoded = new URLSearchParams();
            // urlencoded.append("noOfData", 80);
            urlencoded.append("noOfData", noOfData);
            urlencoded.append("skip", skip);
            const opt = {
                method: 'POST',
                headers: header,
                body: urlencoded,
            }
            console.log("jitu1",data.startAmmount);
            console.log("jitu1",data.gat);
            console.log("jitu1",data.zone);
            // await fetch("https://api.pcmctax.foxberry.link/v1/p4y/ptax4yearList?zone=17",opt)
            await fetch(BASE_URL + `/p4y/ptax4yearList?gat=${data.gat}&zone=${data.zone}&startAmount=${data.startAmmount}`, opt)

                .then(res => {
                    return res.json()
                })

                .then((res) => {
                    // setState({...state, gat:""})
                    setCount("")

                    // console.log("get api data", res);
                    setProperty(res?.data)
                    setCount(res.count);
                    setPageCount(Math.ceil(res.count/postPerPage))
                    setLoader(false)


                    // setArrLength(res.data.length)
                })
                .catch((err) => {
                    setLoader(false)

                    console.log("error from api", err);
                })
        }
         else if (state.gat && state.zone && state.endAmmount) {
            // console.log("Only gat")
            let header = new Headers();
            header.append("Content-Type", "application/x-www-form-urlencoded")
            let urlencoded = new URLSearchParams();
            // urlencoded.append("noOfData", 80);
            urlencoded.append("noOfData", noOfData);
            urlencoded.append("skip", skip);
            const opt = {
                method: 'POST',
                headers: header,
                body: urlencoded,
            }
            
            // await fetch("https://api.pcmctax.foxberry.link/v1/p4y/ptax4yearList?zone=17",opt)
            await fetch(BASE_URL + `/p4y/ptax4yearList?gat=${data.gat}&zone=${data.zone}&endAmount=${data.endAmmount}`, opt)

                .then(res => {
                    return res.json()
                })

                .then((res) => {
                    // setState({...state, gat:""})
                    setCount("")

                    // console.log("get api data", res);
                    setProperty(res?.data)
                    setCount(res.count);
                    setPageCount(Math.ceil(res.count/postPerPage))
                    setLoader(false)


                    // setArrLength(res.data.length)
                })
                .catch((err) => {
                    setLoader(false)

                    console.log("error from api", err);
                })
        }
         else if (state.zone && state.gat) {
            // console.log("only zone")
            let header = new Headers();
            header.append("Content-Type", "application/x-www-form-urlencoded")
            let urlencoded = new URLSearchParams();
            // urlencoded.append("noOfData", 80);
            urlencoded.append("noOfData", noOfData);
            urlencoded.append("skip", skip);
            const opt = {
                method: 'POST',
                headers: header,
                body: urlencoded,
            }
            // await fetch("https://api.pcmctax.foxberry.link/v1/p4y/ptax4yearList?zone=17",opt)
            await fetch(BASE_URL + `/p4y/ptax4yearList?zone=${data.zone}&gat=${data.gat}`, opt)

                .then(res => {
                    return res.json()
                })

                .then((res) => {
                    // setState({...state, zone:""})
                    setCount("")

                    // console.log("get api data", res);
                    setProperty(res?.data)
                    setLoader(false)

                    // setArrLength(res.data.length)
                    setCount(res.count);
                    setPageCount(Math.ceil(res.count/postPerPage))

                })
                .catch((err) => {
                    console.log("error from api", err);
                    setLoader(false)

                })
        } else if(state.zone && state.startAmmount){
            let header = new Headers();
             header.append("Content-Type", "application/x-www-form-urlencoded")
             let urlencoded = new URLSearchParams();
             // urlencoded.append("noOfData", 80);
             urlencoded.append("noOfData", noOfData);
             urlencoded.append("skip", skip);
             const opt = {
                 method: 'POST',
                 headers: header,
                 body: urlencoded,
             }
             console.log("jitu3",data.zone);
             console.log("jitu3",data.startAmmount);
             // await fetch("https://api.pcmctax.foxberry.link/v1/p4y/ptax4yearList?zone=17",opt)
             await fetch(BASE_URL + `/p4y/ptax4yearList?zone=${data.zone}&startAmount=${data.startAmmount}`, opt)
 
                 .then(res => {
                     return res.json()
                 })
 
                 .then((res) => {
                     // setState({...state, zone:""})
                     setCount("")
 
                     // console.log("get api data", res);
                     setProperty(res?.data)
                     setLoader(false)
 
                     // setArrLength(res.data.length)
                     setCount(res.count);
                     setPageCount(Math.ceil(res.count/postPerPage))
 
                 })
                 .catch((err) => {
                     console.log("error from api", err);
                     setLoader(false)
 
                 })

        } else if(state.zone&&state.endAmmount){
            let header = new Headers();
            header.append("Content-Type", "application/x-www-form-urlencoded")
            let urlencoded = new URLSearchParams();
            // urlencoded.append("noOfData", 80);
            urlencoded.append("noOfData", noOfData);
            urlencoded.append("skip", skip);
            const opt = {
                method: 'POST',
                headers: header,
                body: urlencoded,
            }
            // await fetch("https://api.pcmctax.foxberry.link/v1/p4y/ptax4yearList?zone=17",opt)
            await fetch(BASE_URL + `/p4y/ptax4yearList?zone=${data.zone}&endAmount=${data.endAmmount}`, opt)

                .then(res => {
                    return res.json()
                })

                .then((res) => {
                    // setState({...state, zone:""})
                    setCount("")

                    // console.log("get api data", res);
                    setProperty(res?.data)
                    setLoader(false)

                    // setArrLength(res.data.length)
                    setCount(res.count);
                    setPageCount(Math.ceil(res.count/postPerPage))

                })
                .catch((err) => {
                    console.log("error from api", err);
                    setLoader(false)

                })

        } else if(state.gat&&state.startAmmount){
            let header = new Headers();
            header.append("Content-Type", "application/x-www-form-urlencoded")
            let urlencoded = new URLSearchParams();
            // urlencoded.append("noOfData", 80);
            urlencoded.append("noOfData", noOfData);
            urlencoded.append("skip", skip);
            const opt = {
                method: 'POST',
                headers: header,
                body: urlencoded,
            }
            // await fetch("https://api.pcmctax.foxberry.link/v1/p4y/ptax4yearList?zone=17",opt)
            await fetch(BASE_URL + `/p4y/ptax4yearList?zone=${data.zone}&startAmount=${data.startAmmount}`, opt)

                .then(res => {
                    return res.json()
                })

                .then((res) => {
                    // setState({...state, zone:""})
                    setCount("")
                    // console.log("get api data", res);
                    setProperty(res?.data)
                    setLoader(false)

                    // setArrLength(res.data.length)
                    setCount(res.count);
                    setPageCount(Math.ceil(res.count/postPerPage))

                })
                .catch((err) => {
                    console.log("error from api", err);
                    setLoader(false)

                })
        } else if(state.gat&&state.endAmmount){
            let header = new Headers();
            header.append("Content-Type", "application/x-www-form-urlencoded")
            let urlencoded = new URLSearchParams();
            // urlencoded.append("noOfData", 80);
            urlencoded.append("noOfData", noOfData);
            urlencoded.append("skip", skip);
            const opt = {
                method: 'POST',
                headers: header,
                body: urlencoded,
            }
            // await fetch("https://api.pcmctax.foxberry.link/v1/p4y/ptax4yearList?zone=17",opt)
            await fetch(BASE_URL + `/p4y/ptax4yearList?gat=${data.gat}&endAmount=${data.endAmmount}`, opt)

                .then(res => {
                    return res.json()
                })

                .then((res) => {
                    // setState({...state, zone:""})
                    setCount("")

                    // console.log("get api data", res);
                    setProperty(res?.data)
                    setLoader(false)

                    // setArrLength(res.data.length)
                    setCount(res.count);
                    setPageCount(Math.ceil(res.count/postPerPage))

                })
                .catch((err) => {
                    console.log("error from api", err);
                    setLoader(false)

                })
        } else if(state.zone&&state.startAmmount){
            let header = new Headers();
            header.append("Content-Type", "application/x-www-form-urlencoded")
            let urlencoded = new URLSearchParams();
            // urlencoded.append("noOfData", 80);
            urlencoded.append("noOfData", noOfData);
            urlencoded.append("skip", skip);
            const opt = {
                method: 'POST',
                headers: header,
                body: urlencoded,
            }
            // await fetch("https://api.pcmctax.foxberry.link/v1/p4y/ptax4yearList?zone=17",opt)
            await fetch(BASE_URL + `/p4y/ptax4yearList?zone=${data.zone}&startAmount=${data.startAmmount}`, opt)

                .then(res => {
                    return res.json()
                })

                .then((res) => {
                    // setState({...state, zone:""})
                    setCount("")

                    // console.log("get api data", res);
                    setProperty(res?.data)
                    setLoader(false)

                    // setArrLength(res.data.length)
                    setCount(res.count);
                    setPageCount(Math.ceil(res.count/postPerPage))

                })
                .catch((err) => {
                    console.log("error from api", err);
                    setLoader(false)

                })
        } else if(state.startAmmount && state.endAmmount){
            let header = new Headers();
            header.append("Content-Type", "application/x-www-form-urlencoded")
            let urlencoded = new URLSearchParams();
            // urlencoded.append("noOfData", 80);
            urlencoded.append("noOfData", noOfData);
            urlencoded.append("skip", skip);
            const opt = {
                method: 'POST',
                headers: header,
                body: urlencoded,
            }
            console.log("stat and end1",data.startAmmount);
            console.log("stat and end2",data.endAmmount);
            // await fetch("https://api.pcmctax.foxberry.link/v1/p4y/ptax4yearList?zone=17",opt)
            await fetch(BASE_URL + `/p4y/ptax4yearList?startAmount=${data.startAmmount}&endAmount=${data.endAmmount}`, opt)

                .then(res => {
                    return res.json()
                })

                .then((res) => {
                    // setState({...state, zone:""})
                    setCount("")

                    console.log("get api data start ammount and end ammount", res.data);
                    setProperty(res?.data)
                    setLoader(false)

                    // setArrLength(res.data.length)
                    setCount(res.count);
                    setPageCount(Math.ceil(res.count/postPerPage))

                })
                .catch((err) => {
                    console.log("error from api", err);
                    setLoader(false)

                })

        }
        else if(state.zone){
             // console.log("only zone")
             let header = new Headers();
             header.append("Content-Type", "application/x-www-form-urlencoded")
             let urlencoded = new URLSearchParams();
             // urlencoded.append("noOfData", 80);
             urlencoded.append("noOfData", noOfData);
             urlencoded.append("skip", skip);
             const opt = {
                 method: 'POST',
                 headers: header,
                 body: urlencoded,
             }
            //  console.log("jitu2",data.zone);
             // await fetch("https://api.pcmctax.foxberry.link/v1/p4y/ptax4yearList?zone=17",opt)
             await fetch(BASE_URL + `/p4y/ptax4yearList?zone=${data.zone}`, opt)
 
                 .then(res => {
                     return res.json()
                 })
 
                 .then((res) => {
                     // setState({...state, zone:""})
                     setCount("")
 
                     // console.log("get api data", res);
                     setProperty(res?.data)
                     setLoader(false)
 
                     // setArrLength(res.data.length)
                     setCount(res.count);
                     setPageCount(Math.ceil(res.count/postPerPage))
 
                 })
                 .catch((err) => {
                     console.log("error from api", err);
                     setLoader(false)
 
                 })
        } else if(state.gat){
            let header = new Headers();
             header.append("Content-Type", "application/x-www-form-urlencoded")
             let urlencoded = new URLSearchParams();
             // urlencoded.append("noOfData", 80);
             urlencoded.append("noOfData", noOfData);
             urlencoded.append("skip", skip);
             const opt = {
                 method: 'POST',
                 headers: header,
                 body: urlencoded,
             }
            //  console.log("jitu",data.gat);
             // await fetch("https://api.pcmctax.foxberry.link/v1/p4y/ptax4yearList?zone=17",opt)
             await fetch(BASE_URL + `/p4y/ptax4yearList?gat=${data.gat}`, opt)
 
                 .then(res => {
                     return res.json()
                 })
 
                 .then((res) => {
                     // setState({...state, zone:""})
                     setCount("")
 
                     // console.log("get api data", res);
                     setProperty(res?.data)
                     setLoader(false)
 
                     // setArrLength(res.data.length)
                     setCount(res.count);
                     setPageCount(Math.ceil(res.count/postPerPage))
 
                 })
                 .catch((err) => {
                     console.log("error from api", err);
                     setLoader(false)
 
                 })

        }else if(state.startAmmount){
            let header = new Headers();
             header.append("Content-Type", "application/x-www-form-urlencoded")
             let urlencoded = new URLSearchParams();
             // urlencoded.append("noOfData", 80);
             urlencoded.append("noOfData", noOfData);
             urlencoded.append("skip", skip);
             const opt = {
                 method: 'POST',
                 headers: header,
                 body: urlencoded,
             }
            //  console.log("jitu2",data.startAmmount);
             // await fetch("https://api.pcmctax.foxberry.link/v1/p4y/ptax4yearList?zone=17",opt)
             await fetch(BASE_URL + `/p4y/ptax4yearList?startAmount=${data.startAmmount}`, opt)
 
                 .then(res => {
                     return res.json()
                 })
 
                 .then((res) => {
                     // setState({...state, zone:""})
                     setCount("")
 
                     // console.log("get api data", res);
                     setProperty(res?.data)
                     setLoader(false)
 
                     // setArrLength(res.data.length)
                     setCount(res.count);
                     setPageCount(Math.ceil(res.count/postPerPage))
 
                 })
                 .catch((err) => {
                     console.log("error from api", err);
                     setLoader(false)
 
                 })

        }else if(state.endAmmount){
            let header = new Headers();
             header.append("Content-Type", "application/x-www-form-urlencoded")
             let urlencoded = new URLSearchParams();
             // urlencoded.append("noOfData", 80);
             urlencoded.append("noOfData", noOfData);
             urlencoded.append("skip", skip);
             const opt = {
                 method: 'POST',
                 headers: header,
                 body: urlencoded,
             }
             console.log("jitu1",data.endAmmount);
             // await fetch("https://api.pcmctax.foxberry.link/v1/p4y/ptax4yearList?zone=17",opt)
             await fetch(BASE_URL + `/p4y/ptax4yearList?endAmount=${data.endAmmount}`, opt)
 
                 .then(res => {
                     return res.json()
                 })
 
                 .then((res) => {
                     // setState({...state, zone:""})
                     setCount("")
 
                     // console.log("get api data", res);
                     setProperty(res?.data)
                     setLoader(false)
 
                     // setArrLength(res.data.length)
                     setCount(res.count);
                     setPageCount(Math.ceil(res.count/postPerPage))
 
                 })
                 .catch((err) => {
                     console.log("error from api", err);
                     setLoader(false)
 
                 })

        }

        }
        else{
            console.log("validation error");
        }
        
        




    }
    const handleClick11 = async (noOfData, skip) => {
        if(validation()){
            setLoader(true)
        //    console.log("vghxvbcjdbb data 7797",noOfData)
        
        const { startAmmount, endAmmount,zone,gat } = state

        const data = {
            startAmmount: startAmmount,
            endAmmount: endAmmount,
            zone:zone,
            gat:gat

        }
        // const data3 = {
        //     gat: "20.0"
        // }
        
        // axios.get(`http://api.pcmctax.foxberry.link/v1/p4y/ptax4yearList?zone=${data.zone}`)
        let header = new Headers();
        header.append("Content-Type", "application/x-www-form-urlencoded")
        let urlencoded = new URLSearchParams();
        // urlencoded.append("noOfData", 80);
        urlencoded.append("noOfData", noOfData);
        urlencoded.append("skip", skip);
        const opt = {
            method: 'POST',
            headers: header,
            body: urlencoded,
        }
        await fetch(BASE_URL + `/p4y/ptax4yearList?startAmount=${data.startAmmount}&endAmount=${data.endAmmount}`, opt)
            //  await fetch(`https://api.pcmctax.foxberry.link/v1/p4y/{ptax4yearList?${(zone=${data.zone}||gat=${data3.gat})||(zone=${data.zone}&&gat=${data3.gat}) }}`,opt)
            .then(res => {
                return res.json()
            })

            .then((res) => {
                // setState({ ...state, endAmmount: "", startAmmount: "" })
                // setCount();
                setLoader(false)
                // console.log("API", res)
                if (res === "No data found") {
                    setProperty("")
                    Nodata()
                setCount();

                }
                else if (res.data) {
                    
                    setProperty(res.data)
                    setArrLength(res.data.length)
                    // console.log("Count", res.count)
                    setCount(res.count);
                    setPageCount(Math.ceil(res.count/10))

                }
            })
            .catch((err) => {
                console.log("error from api", err);
                setLoader(false)
                alert("api error")
            })
        }
        else{
            console.log("validation error");
        }
        

    }
    const handleClick1 = async (noOfData, skip) => {
        if(validation()){
            setLoader(true)

            const { startAmmount, endAmmount } = state

            const data = {
                startAmmount: startAmmount,
                endAmmount: endAmmount
            }
        if (state.startAmmount && state.endAmmount) {
            let header = new Headers();
            header.append("Content-Type", "application/x-www-form-urlencoded")
            let urlencoded = new URLSearchParams();
            // urlencoded.append("noOfData", 80);
            urlencoded.append("noOfData", noOfData);
            urlencoded.append("skip", skip);
            const opt = {
                method: 'POST',
                headers: header,
                body: urlencoded,
            }
            // console.log("data dot zone",noOfData);
            // await fetch("https://api.pcmctax.foxberry.link/v1/p4y/ptax4yearList?zone=17",opt)
            await fetch(BASE_URL + `/p4y/ptax4yearList?startAmount=${data.startAmmount}&endAmount=${data.endAmmount}`, opt)

                .then(res => {
                    return res.json()

                })
     
                .then((res) => {
                    // setState({ ...state, endAmmount: "", startAmmount: "" })
                    // setCount();
                    setLoader(false)
                    // console.log("API", res)
                    if (res === "No data found") {
                        setProperty("")
                        Nodata()
                    setCount();
    
                    }
                    else if (res.data) {
                        
                        setProperty(res.data)
                        setArrLength(res.data.length)
                        // console.log("Count", res.count)
                        setCount(res.count);
                        setPageCount(Math.ceil(res.count/10))
    
                    }
                })
                .catch((err) => {
                    console.log("error from api", err);
                    setLoader(false)
                    alert("api error")
                })
        } else if (state.startAmmount) {
            // console.log("Only gat")
            let header = new Headers();
            header.append("Content-Type", "application/x-www-form-urlencoded")
            let urlencoded = new URLSearchParams();
            // urlencoded.append("noOfData", 80);
            urlencoded.append("noOfData", noOfData);
            urlencoded.append("skip", skip);
            const opt = {
                method: 'POST',
                headers: header,
                body: urlencoded,
            }
            // await fetch("https://api.pcmctax.foxberry.link/v1/p4y/ptax4yearList?zone=17",opt)
            await fetch(BASE_URL + `/p4y/ptax4yearList?startAmount=${data.startAmmount}`, opt)

                .then(res => {
                    return res.json()
                })

                .then((res) => {
                    // setState({ ...state, endAmmount: "", startAmmount: "" })
                    // setCount();
                    setLoader(false)
                    // console.log("API", res)
                    if (res === "No data found") {
                        setProperty("")
                        Nodata()
                    setCount();
    
                    }
                    else if (res.data) {
                        
                        setProperty(res.data)
                        setArrLength(res.data.length)
                        // console.log("Count", res.count)
                        setCount(res.count);
                        setPageCount(Math.ceil(res.count/10))
    
                    }
                })
                .catch((err) => {
                    console.log("error from api", err);
                    setLoader(false)
                    alert("api error")
                })
        } else if (state.endAmmount) {
            // console.log("only zone")
            let header = new Headers();
            header.append("Content-Type", "application/x-www-form-urlencoded")
            let urlencoded = new URLSearchParams();
            // urlencoded.append("noOfData", 80);
            urlencoded.append("noOfData", noOfData);
            urlencoded.append("skip", skip);
            const opt = {
                method: 'POST',
                headers: header,
                body: urlencoded,
            }
            // await fetch("https://api.pcmctax.foxberry.link/v1/p4y/ptax4yearList?zone=17",opt)
            await fetch(BASE_URL + `/p4y/ptax4yearList?endAmount=${data.endAmmount}`, opt)

                .then(res => {
                    return res.json()
                })

                .then((res) => {
                    // setState({ ...state, endAmmount: "", startAmmount: "" })
                    // setCount();
                    setLoader(false)
                    // console.log("API", res)
                    if (res === "No data found") {
                        setProperty("")
                        Nodata()
                    setCount();
    
                    }
                    else if (res.data) {
                        
                        setProperty(res.data)
                        setArrLength(res.data.length)
                        // console.log("Count", res.count)
                        setCount(res.count);
                        setPageCount(Math.ceil(res.count/10))
    
                    }
                })
                .catch((err) => {
                    console.log("error from api", err);
                    setLoader(false)
                    alert("api error")
                })
        }
        // console.log("property", property);
     }
    }

    const getGatList = () => {
        axios.get(BASE_URL + `/gat/gatlist`)
            .then((res) => {
                // console.log("response gat List", res.data);
                setGatlist(res.data)
                setfiltergat(res.data)
            })
            .catch((err) => {
                console.log("eroor from api", err);
            })
    }
    const getZone = () => {
        axios.get(BASE_URL + `/zone/zonelist`)
            .then((res) => {
                // console.log("response zon list", res.data);
                setZoneList(res.data)
            })
            .catch((err) => {
                console.log("eroor from api", err);
            })
    }

    useEffect(() => {
        if(state.zone === "" && state.gat === ""){
            getGatList()
            getZone()
            initialData(postPerPage,0)
            setSkip(0)
        }
    }, [state.zone,state.gat])


    
    const [selectedGat, setSelectedGat ]=useState([]);
    useEffect(()=>{
        if(state.zone){
            getGat()
        }else{
            setGatlist(filtergat)
        }

    },[state.zone])
    

    const getGat =()=>{

            console.log("state of zone", state.zone);
            console.log("Gat List", gatlist);
        
        const result = filtergat.filter((event) => event.zonekey == state.zone);
        console.log("result 123",result);
        setGatlist(result) 
    }


    


    const indexOflastPost = currentPage * postPerPage;
    const indexOfFirstPost = indexOflastPost - postPerPage;
    const currentPost = property?.slice(indexOfFirstPost, indexOflastPost);
    const [skip, setSkip] = useState(0)

    const nextPage = currentPost + 1
    const prevpage = currentPost - 1


const handleScroll = (e) => {
  const bottom =
    e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
  if (bottom) {
    console.log("Reached at Bottom");
    let val = postPerPage + 10
    setPostPerPage(val)
    if(!state.zone && !state.gat){
      initialData(val, "0")
    }
    else{
      handleClick(postPerPage, "0");
    }
  }
};

const downloadExcel = (customHeadings) => {
    let dataSet = [];
    console.log("dataset", dataSet)
    dataSet = customHeadings;
    const worksheet = XLSX.utils.json_to_sheet(dataSet);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    XLSX.writeFile(workbook, "CouponReport.xlsx");
    SetSheetLoader(false)
};


const [newProperty, SetNewProperty] =useState([]);
const [sheetLoader, SetSheetLoader]= useState(false);

const CustomExcel = () => {

    SetSheetLoader(true)
    let urlencoded = new URLSearchParams();
    urlencoded.append("noOfData", 100);
    urlencoded.append("skip", skip);
    const opt = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: urlencoded,
    }
     fetch(BASE_URL + `/p4y/ptax4yearList`, opt).then(res => res.json())
    .then(response => {
        // setProperty("")
        SetNewProperty(response.data)
        
        // setCount()
        // setProperty()
        // console.log("get api data from initial", response);
        // setProperty(response?.data)
        // setCount(response?.count)
        // setPageCount(Math.ceil(response.count/postPerPage))

        const customHeadings = response.data.length > 0 && response.data.map(data => ({
            "Property Name": data.propertyname ? data.propertyname : '-',
            "Property Code": data.propertycode_x ? data.propertycode_x : '-',
            "Bill Amount": data.billamount ? data.billamount : '-',
        }))
        console.log("Customm Excel", customHeadings)
        downloadExcel(customHeadings)
    })
        .catch((err) => {
            setLoader(false)
            console.log("error from api", err);
        })


    console.log("Count in CustomExcel", count);

};
let numFormat = Intl.NumberFormat('en-IN');
    return (
        <div>

     <NavBar/>

        <Container  className="mt-3">
        <ToastContainer autoClose={4000} />
            <Row className="d-flex justify-content-center align-items-center">
                <Col xs={6} md={3} className='mb-3 md-mb-0'>
                <label for="exampleFormControlSelect2"><b>Select Zone </b> </label>
                    <select className="form-select" 
                    aria-label=".form-select-lg example" name="zone" value={state.zone} onChange={handleInputs}   >
                    <option  value="">Zone</option>
                     {
                        zoneList != undefined && zoneList != null ? zoneList.map((data, i) => {
                            return (
                                <option value={data.zonekey}>{data.zonename}</option> 
                            )
                        }) : ""
                    }
                </select>
                </Col>

                <Col xs={6} md={3} className='mb-3 md-mb-0'>
                <label for="exampleFormControlSelect2"><strong>Select Gat </strong></label>

               <select className="form-select" name="gat" value={state.gat} onChange={handleInputs} 
               disabled={gatlist.length === 0}>
                <option value=""  >Gat </option>
            {
                gatlist != undefined && gatlist != null ? gatlist.map((data, i) => {
                    return (
                        <option value={data.gatkey}>{data.gatkey}-{data.mar_gatname}</option>


                    ) 
                }) : ""
            }
        </select>
  </Col>

  <Col xs={6} md={3} className='mb-4 md-mb-0'>
      <label for="exampleFormControlSelect1"><strong>Start Amount</strong></label>
            <InputGroup>
                <InputGroup.Text id="basic-addon1">₹</InputGroup.Text>
                    <Form.Control placeholder="Start" aria-label="Start" aria-describedby="basic-addon1" name="startAmmount"
                       value={state.startAmmount} onChange={(e)=>{  if (!e.target.value.match
                        (/[A-Za-z+@#$&%!~=^_:(){}\[\]*.|/\s/g/\-/?<>,;`'""/\\]/) && e.target.value.length <= 10) {
                                                handleInputs(e); }}
                        }/>
                    </InputGroup>
  </Col>

  <Col md={3} xs={6} className='mb-4 md-mb-0'>
  <label for="exampleFormControlSelect2"><strong>End Amount</strong></label>
         <InputGroup>
             <InputGroup.Text id="basic-addon2">₹</InputGroup.Text>
                <Form.Control placeholder="End"  aria-label="End" aria-describedby="basic-addon2"
                     name="endAmmount" value={state.endAmmount} onChange={(e)=>{   
                        if (!e.target.value.match( /[A-Za-z+@#$&%!~=^_:(){}\[\]*.|/\s/g/\-/?<>,;`'""/\\]/) &&
                                               e.target.value.length <= 10 ) {
                                               handleInputs(e);
                              }}
                        }/>
        </InputGroup>
  </Col>

 
</Row>

<Row>
<Col xs={12} className='d-flex text-center justify-content-center'>
         <Button type="submit" className='btn btn-submit me-3' onClick={ (e) => { e.preventDefault();setFlag(true);handleClick(postPerPage,0);setSkip(0) } } >
         {loader?<Spinner size="sm"/>:<><Icon.ArrowRepeat className='me-2' size="20"/>Get Data</>}</Button>
        <Button className='btn-submit' onClick={ () => CustomExcel() }>{sheetLoader ?<center> <Spinner size="sm"/></center>
         : <> <Icon.FileEarmarkSpreadsheet className="me-2"/>
        Export 100 Records</>}
        </Button>
    </Col>
</Row>


     {/* --------- Totak Count Data Scroll  -----------*/}
     <Row  className="PropertyScroll mt-2 justify-content-center">
       <div className="mt-2 d-flex justify-content-start align-items-center">
        <Icon.PlusCircleFill className="me-2 icon" size={20}/>
            <h5 className="me-2">Total Count -</h5>
            <p>{count}</p>
       </div>
        <Col md={10} className="myCard pb-5 mt-1 mb-5 md-mb-0" onScroll={handleScroll} style={{ height: "400px", overflowY: "scroll" }}>
           
            {currentPost?.map((data, i) => {
                  return (
                    <div className="card mb-4">
                      <div className="card-body w-100">
                        <Row className="d-flex justify-content-center">
                            <Col md={12} className='d-flex justify-content-center align-items-center'>
                              <div><Icon.PersonCircle size={20} className="me-2 icon"/></div>
                                <p className="card-text"> <strong>Name : </strong>{data.propertyname}</p>
                            </Col>
                        </Row>

                        <Row className="text-center">
                          <Col md={6} className='mt-2 d-flex justify-content-center align-items-center'>
                          <div><Icon.Bank2 size={20} className="me-2 icon"/></div>
                            <p className="card-text">
                              {" "}<strong>Property Code: </strong>{data.propertycode_x}</p>
                          </Col>
                          <Col md={6} className='mt-2 d-flex justify-content-center align-items-center'>
                          <div><Icon.CurrencyRupee size={20} className="me-2 icon"/></div>
                          <p className="card-text">
                              <strong>Bill Amount : </strong>
                              {numFormat.format(data.billamount)}
                            </p>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  );
                })}

                {loader ? <center><Spinner/></center> :""}
              </Col>
              {/* <Col md={5} className='mb-5 pb-5 md-mb-0 md-pb-0'>
                        <Card className="w-100 h-100 text-center">
                            <h1>Charts</h1>
                            <img src="https://img.freepik.com/premium-vector/work-progress_24908-53218.jpg?w=2000"
                            alt="Under Dev" className="card-img-top"/>
                            <p>Chart Need to be updated </p>
                        </Card>
                </Col> */}
     </Row>
        </Container>



            {/* <Container fluid>
             
                <Row className="d-flex justify-content-center align-items-center h-100">



                    <Col md={8} className="text-center">


                        <form >

                            <Row className="justify-content-center mt-3 ms-3">
                            <Col xs={6} md={3} >
                                    
                                    <label for="exampleFormControlSelect2"><b>Select Zone </b> </label>

                  <select className="form-select form-select-lg .dropdown .dropdown-menu{ max-height:100px; overflow:auto; }" aria-label=".form-select-lg example" name="zone" value={state.zone}
                   onChange={handleInputs}   >
                      <option  value=""  >Zone</option>
                      {
                          zoneList != undefined && zoneList != null ? zoneList.map((data, i) => {
                              return (
                                  <option value={data.zonekey}>{data.zonename}</option> 
                                )
                          }) : ""
                      }
                  </select>
              </Col>
              <Col xs={6} md={3} className='text-center'>
                                <label for="exampleFormControlSelect2"><strong>Select Gat </strong></label>

                                    <select className="form-select form-select-lg"
                                        aria-label=".form-select-lg example"
                                        name="gat"
                                        value={state.gat}
                                        onChange={handleInputs}
                                        disabled={gatlist.length === 0}>
                                        <option value=""  >Gat </option>

                                        {
                                            gatlist != undefined && gatlist != null ? gatlist.map((data, i) => {
                                                return (
                                                    <option value={data.gatkey}>{data.mar_gatname}</option>


                                                )
                                            }) : ""
                                        }
                                    </select>


                                </Col>
                                <Col xs={6} md={3}>
                                    <label for="exampleFormControlSelect1"><strong>Start Amount</strong></label>
                                    <InputGroup className="mb-3">

                                        <InputGroup.Text id="basic-addon1">₹</InputGroup.Text>

                                        <Form.Control
                                            placeholder="Start"
                                            aria-label="Start"
                                            aria-describedby="basic-addon1"
                                            name="startAmmount"
                                            value={state.startAmmount} 
                                            onChange={(e)=>{   
                                                 if (!e.target.value.match(
                                                  /[A-Za-z+@#$&%!~=^_:(){}\[\]*.|/\s/g/\-/?<>,;`'""/\\]/
                                                ) &&
                                                e.target.value.length <= 10
                                              ) {
                                                handleInputs(e);
                                              }}
                                            
                

                                              }
                                              />
                                    </InputGroup>
                                    </Col>  

                                <Col xs={6} md={3}>
                                    <label for="exampleFormControlSelect2"><strong>End Amount</strong></label>
                                    <InputGroup className="mb-3">
                                        <InputGroup.Text id="basic-addon2">₹</InputGroup.Text>
                                        <Form.Control
                                            placeholder="End"
                                            aria-label="End"
                                            aria-describedby="basic-addon2"
                                            name="endAmmount"
                                           
                                            value={state.endAmmount}
                                            onChange={(e)=>{   
                                                if (!e.target.value.match(
                                                 /[A-Za-z+@#$&%!~=^_:(){}\[\]*.|/\s/g/\-/?<>,;`'""/\\]/
                                               ) &&
                                               e.target.value.length <= 10
                                             ) {
                                               handleInputs(e);
                                             }}
                                    

                                             }
                                            />
                                    </InputGroup>
                                </Col>

                                 
                            </Row>
         <Row style={{display:"flex",justifyContent:"center"}}>
                <Col xs={12} md={2} className='d-flex justify-content-center align-items-center mt-2'>
                     <Button type="submit" className='btn btn-submit'onClick={ (e) => { e.preventDefault();setFlag(true);handleClick(postPerPage,0);setSkip(0) } } >Get Data</Button>
                 </Col>
        </Row>
                           
                            {
                      
                      count ? <Row className="mt-2" >
                                    <Col><strong>Total Count :</strong> {count}</Col>
                                </Row> : " "
                            }

                        </form>




                        {loader ?
                         <div class="spinner-border" role="status">
                         <span class="sr-only">Loading...</span>
                        </div> :
                      <Col>
                        {property ?
                            <Col className="myCard pb-5" id="style-3" style={{ height: "64vh", overflowY: 'scroll' }}>
                                {
                                    currentPost?.map((data, i) => {
                                        return (
                                            <div className="card text-center m-4">


                                                <div className="card-body w-100" style={{ boxShadow: "  rgba(99, 99, 99, 0.2) 0px 2px 8px 0px" }}>
                                                    <Row>
                                                        <Col md={6}>
                                                            <p className="card-text md-6 "> <strong>Name : </strong>{data.propertyname}</p>
                                                        </Col>

                                                        <Col md={6} style={{display:"flex"}}>

                                                        <Col md={6}>
                                                            <p className="card-title md-6"><strong>Zone : </strong>{data.zone}</p> {" "}
                                                        </Col>
                                                        <Col md={6}>
                                                            <p className="card-text sm-12"><strong>Gat :</strong>{data.gat}</p>
                                                        </Col>
                                                        </Col>
                                                    </Row>

                                                    <Row>
                                                        
                                                        
                                                        <Col md={6}>
                                                            <p className="card-text md-6 "> <strong>Property Code X : </strong>{data.propertycode_x}</p>
                                                        </Col>
                                                        <Col md={6}>
                                                            <p className="card-text"><strong>Bill Amount : </strong>{data.billamount}</p>
                                                        </Col>
                                                    </Row>

                                                </div>
                                            </div>
                                        )

                                    })
                                }
                         
                                <Row className="d-flex me-5 ms-5 " style={{zIndex:"111"}}>
                                    


                                </Row>
                                


                            </Col> : ""}
                            </Col>
}




                    </Col>
                    <Col md={4}>
                        <Card className="w-100 h-100 p-3 text-center">
                            <h1>Charts</h1>
                            <img src="https://img.freepik.com/premium-vector/work-progress_24908-53218.jpg?w=2000"
                            alt="Under Dev" className="card-img-top"/>
                            <p>Chart Need to be updated </p>

                        </Card>
                    </Col>
                </Row>
            </Container> */}
        </div>
    );
}
{/* <Form.Select className="w-50" aria-label="Default select example" name="record" value={record} onChange={handleChange} >
      <option>Open this select menu</option>
      <option value="10">10</option>
      <option value="25">20</option>
      <option value="50">30</option>
      <option value="100">40</option>
      <option value="150">50</option>
    </Form.Select> */}



    // {property ? <Col >
    //     <nav className="mt-1 mb-2" aria-label="Page navigation example mb-0">
    //         <ul class="pagination justify-content-end">
    //             <li class="page-item  me-3" >
    //                 {/* <Button onClick={prev} className='btn-submit' disabled={skip<postPerPage ? true : false}>Previous</Button> */}
    //                 <Button onClick={()=>{prev()}} className='btn-submit' disabled={skip<postPerPage ? true : false}>Previous</Button>
    //             </li>

    //             <li class="page-item">
    //                 {/* <Button onClick={next} className='btn-submit' disabled={skip > count || skip === count ? true : false}>Next</Button> */}

    //                 <Button onClick={()=>{next()}} className='btn-submit' disabled={pageCount === currentPageCount ? true : false}>Next</Button>
    //             </li>
    //         </ul>
    //     </nav>
    // </Col>
    //     : ""}